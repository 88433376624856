import { GraphQLClient } from 'graphql-request';

import { ID } from '../../../../types';

import { FetchProductsSetByUserIdScopeType } from '../../productsSetsTypes';

import { GRAPHQL_API_URI } from '../../../../config';

interface FetchProductsSetByUserIdProps {
  query: string;
  input: FetchProductsSetByUserIdInput;
}

const graphQLClient = new GraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
});

export interface FetchProductsSetByUserIdInput {
  userId: ID;
  scope?: FetchProductsSetByUserIdScopeType;
}

export function fetchProductsSetByUserId({
  query,
  input
}: FetchProductsSetByUserIdProps) {
  return graphQLClient.request(query, input);
}
