import { useCallback } from 'react';

import {
  ProductFavorite,
  ProductUUID,
  UpdateProductCacheAction,
  ToggleProductFavoriteGqlQuery,
  ToggleProductFavoriteGqlStatus,
  ToggleProductFavoriteCacheKeys,
  ToggleProductFavoriteGqlError
} from '../../productsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleProductFavoriteInput {
  clientMutationId?: string;
  uuid: ProductUUID;
}

interface ToggleProductFavoriteError {
  fullMessages: ToggleProductFavoriteGqlError;
}

interface ToggleProductFavoriteResponse<ToggleProductFavoriteItemType> {
  toggleProductFavorite: {
    status: ToggleProductFavoriteGqlStatus;
    recordUuid: ProductUUID;
    record: ToggleProductFavoriteItemType;
    errors: ToggleProductFavoriteError;
  };
}

interface ToggleProductFavoriteOptions<ToggleProductFavoriteItemType> {
  query: ToggleProductFavoriteGqlQuery;
  cacheKeys?: ToggleProductFavoriteCacheKeys;
  updateProductCache?: UpdateProductCacheAction<ToggleProductFavoriteItemType>;
}

const action = 'toggleProductFavorite';

interface ToggleProductFavoriteItem {
  favorite: ProductFavorite;
}

function useToggleProductFavorite<
  ToggleProductFavoriteItemType extends ToggleProductFavoriteItem
>({
  query,
  cacheKeys,
  updateProductCache
}: ToggleProductFavoriteOptions<ToggleProductFavoriteItemType>) {
  const handleOptimisticUpdate = useCallback<
    (input: ToggleProductFavoriteInput) => null | (() => void)
  >(
    (input) => {
      return updateProductCache?.({
        selector: { uuid: input?.uuid },
        updateFunction: (prevProduct) => ({
          ...prevProduct,
          favorite: !prevProduct?.favorite
        })
      });
    },
    [updateProductCache]
  );

  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleProductFavoriteInput,
    ToggleProductFavoriteResponse<ToggleProductFavoriteItemType>,
    ToggleProductFavoriteError,
    ToggleProductFavoriteItemType
  >({
    action,
    cacheKeys,
    query,
    onOptimisticUpdate: updateProductCache ? handleOptimisticUpdate : undefined
  });

  return {
    toggleProductFavoriteData: updateQueryData,
    toggleProductFavoriteError: updateQueryError,
    toggleProductFavoriteLoading: updateQueryLoading,
    toggleProductFavoriteErrorMessage: updateQueryErrorMessage,
    toggleProductFavorite: updateQuery,
    toggleProductFavoriteReset: updateQueryReset
  };
}

export default useToggleProductFavorite;
