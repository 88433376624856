import { CreatedAt, DeviceNanoId, ID, NanoID, UUID } from '../../../types';

import {
  CategoryID,
  CategoryLocalizedName,
  CategoryName,
  CategoryUUID
} from '../../categories/categoriesTypes';

import {
  ImageFile,
  ImageName,
  ImageSize,
  ImageUUID
} from '../../images/imagesTypes';

import {
  UserCurrentTeamNanoID,
  UserFullName,
  UserID,
  UserName,
  UserNanoID
} from '../../users/usersTypes';

import { ImageID } from '../../images/imagesTypes';

import {
  MaxFileFile,
  MaxFileID,
  MaxFileName,
  MaxFileSize,
  MaxFileUUID
} from '../../maxFiles/maxFilesTypes';

export type ProductID = ID;
export type ProductUUID = UUID;
export type ProductNanoID = NanoID;
export type ProductRenderTypeID = ID;
export type ProductRenderTypeName = string;

export type ProductBlocked = boolean;
export type ProductFavorite = boolean;
export type ProductName = string;
export type ProductSku = string;
export type ProductNda = boolean;
export type ProductCreatedAt = CreatedAt;
export type ProductDeviceNanoId = DeviceNanoId;
export type ProductLocalizedName = string;
export type ProductNameEn = string;

export type ProductImageID = ImageID;
export type ProductImageName = ImageName;
export type ProductImageSize = ImageSize;
export type ProductImageUUID = ImageUUID;
export type ProductImageFile = ImageFile;

export type ProductMaxFileID = MaxFileID;
export type ProductMaxFileName = MaxFileName;
export type ProductMaxFileSize = MaxFileSize;
export type ProductMaxFileUUID = MaxFileUUID;
export type ProductMaxFileFile = MaxFileFile;

export type ProductBrandID = CategoryID;
export type ProductBrandUUID = CategoryUUID;
export type ProductBrandName = CategoryName;
export type ProductBrandLocalizedName = CategoryLocalizedName;

export type ProductCategoryID = CategoryID;
export type ProductCategoryName = CategoryName;

export type ProductClientID = UserID;
export type ProductClientNanoId = UserNanoID;
export type ProductClientName = UserName;
export type ProductClientFullName = UserFullName;
export type ProductClientCurrentTeamNanoId = UserCurrentTeamNanoID;

export type ProductStyleID = CategoryID;
export type ProductStyleName = CategoryName;

export type ProductCollectionID = CategoryID;
export type ProductCollectionName = CategoryName;

export type ProductMaterialID = CategoryID;
export type ProductMaterialName = CategoryName;

export type ProductColorID = CategoryID;
export type ProductColorName = CategoryName;

export type ProductTagID = CategoryID;
export type ProductTagName = CategoryName;

export type ProductStatusID = CategoryID;
export type ProductStatusName = CategoryName;

export type ProductMistakeTypeID = CategoryID;
export type ProductMistakeTypeName = CategoryName;

export type ProductFileVersionTagID = CategoryID;
export type ProductFileVersionTagName = CategoryName;

export const enum ProductFields {
  NAME = 'name',
  SKU = 'sku',
  PRODUCT_CATEGORY_ID = 'productCategoryId',
  BRAND_ID = 'brandId',
  CLIENT_IDS = 'clientIds',
  STYLE_ID = 'styleId',
  COLLECTION_IDS = 'collectionIds',
  MATERIAL_IDS = 'materialIds',
  COLOR_IDS = 'colorIds',
  TAG_IDS = 'tagIds',
  STATUS_IDS = 'statusIds',
  MISTAKE_TYPE_IDS = 'mistakeTypeIds',
  FILE_VERSION_TAG_IDS = 'fileVersionTagIds',
  RENDER_TYPES_IDS = 'renderTypeIds',
  NDA = 'nda',
  IMAGE_IDS = 'imageIds',
  MAX_FILE_IDS = 'maxFileIds'
}
