import { gql } from 'graphql-request';

import {
  ProductsSetID,
  ProductsSetSelectedProductsID,
  ProductsSetSelectedProductsProductBrandName,
  ProductsSetSelectedProductsProductBrandLocalizedName,
  ProductsSetSelectedProductsProductBlocked,
  ProductsSetSelectedProductsProductFavorite,
  ProductsSetSelectedProductsProductBrandUUID,
  ProductsSetSelectedProductsProductID,
  ProductsSetSelectedProductsProductImageFile,
  ProductsSetSelectedProductsProductImageUUID,
  ProductsSetSelectedProductsProductName,
  ProductsSetSelectedProductsProductNda,
  ProductsSetSelectedProductsProductUUID,
  ProductsSetSelectedProductsUUID,
  ProductsSetUUID
} from '../productsSetsTypes';

export interface FetchProductsSetByUserIdQueryResponse {
  id: ProductsSetID;
  uuid: ProductsSetUUID;
  selectedProducts: {
    id: ProductsSetSelectedProductsID;
    uuid: ProductsSetSelectedProductsUUID;
    product: {
      id: ProductsSetSelectedProductsProductID;
      uuid: ProductsSetSelectedProductsProductUUID;
      name: ProductsSetSelectedProductsProductName;
      nda: ProductsSetSelectedProductsProductNda;
      blocked: ProductsSetSelectedProductsProductBlocked;
      favorite: ProductsSetSelectedProductsProductFavorite;
      image: {
        uuid: ProductsSetSelectedProductsProductImageUUID;
        file: ProductsSetSelectedProductsProductImageFile;
      };
      brand: {
        uuid: ProductsSetSelectedProductsProductBrandUUID;
        name: ProductsSetSelectedProductsProductBrandName;
        localizedName: ProductsSetSelectedProductsProductBrandLocalizedName;
      };
    };
  }[];
}

export const FETCH_PRODUCTS_SET_BY_USER_ID_QUERY = gql`
  query ProductsSetByUserId($userId: ID!, $scope: ID) {
    productsSetByUserId(userId: $userId, scope: $scope) {
      id
      uuid
      selectedProducts {
        id
        uuid
        product {
          id
          uuid
          name
          nda
          blocked
          favorite
          image {
            uuid
            file
          }
          brand {
            uuid
            name
            localizedName
          }
        }
      }
    }
  }
`;
