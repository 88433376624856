import React from 'react';

import { ClassName } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  ProductSelectButtonProduct,
  ProductSelectButtonProductsSet,
  ProductSelectButtonOnSelect
} from './ProductSelectButton.types';
import {
  FetchProductsSetsCacheKeys,
  UpdateProductsSetCacheAction
} from '../../../../productsSets/productsSetsTypes';
import {
  OnSelectedProductsSidebarOpenAction,
  OnSelectedProductsSidebarCloseAction
} from '../../../productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { useProductSelectButton } from './hooks/useProductSelectButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { words } from '../../../../../locales/keys';

interface ProductSelectButtonProps {
  product: ProductSelectButtonProduct;
  productsSet: ProductSelectButtonProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen?: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose?: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductSelectButtonProductsSet>;
  onSelect?: ProductSelectButtonOnSelect;
  selectedClassName: ClassName;
  unselectedClassName: ClassName;
  iconClassName?: ClassName;
}

function ProductSelectButton({
  product,
  productsSet,
  productsSetCacheKeys,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  onSelect,
  selectedClassName,
  unselectedClassName,
  iconClassName
}: ProductSelectButtonProps) {
  const { isSelected, handleToggleSelected } = useProductSelectButton({
    product,
    productsSet,
    onSelectedProductsSidebarOpen,
    onSelectedProductsSidebarClose,
    updateProductsSetCache,
    onSelect,
    productsSetCacheKeys
  });

  return (
    <PureTooltipIconButtonHelper
      className={isSelected ? selectedClassName : unselectedClassName}
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? IconsEnum.CHECK : null}
      iconClassName={iconClassName || 'h-4 w-4'}
      tooltipI18nText={words.selectToAddToTheProject}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default ProductSelectButton;
