import React, { memo } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  SelectedProductsListItemProductsSet,
  SelectedProductsListItemSelectedProduct
} from './SelectedProductsListItem.types';
import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../productsSets/productsSetsTypes';
import { OnSelectedProductsSidebarCloseAction } from '../../../../products/productsTypes';

import { SelectedProductsListItemPreview } from './components/SelectedProductsListItemPreview';
import { SelectedProductsListItemDeleteButton } from './components/SelectedProductsListItemDeleteButton';
import { ProductsListItemNda } from '../../../../products/components/lists/ProductsListItem/components/ProductsListItemNda';
import { DownloadSelectedProductModalButton } from '../../buttons/DownloadSelectedProductModalButton';
import { SubmitProductToProjectOrTaskModalButton } from '../../../../products/components/lists/ProductsListItem/components/SubmitProductToProjectOrTaskModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { SelectedProductsPermissions } from '../../../selectedProductsConstants';
import { ProductsPermissions } from '../../../../products/productsConstants';

import { words } from '../../../../../locales/keys/words';

interface SelectedProductsListItemProps {
  productsSet: SelectedProductsListItemProductsSet;
  selectedProduct: SelectedProductsListItemSelectedProduct;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onLightboxOpen: OnLightboxOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsListItemProductsSet>;
}

function SelectedProductsListItem({
  productsSet,
  selectedProduct,
  productsSetCacheKeys,
  onLightboxOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache
}: SelectedProductsListItemProps) {
  const product = selectedProduct.product;

  return (
    <li className="flex items-center overflow-hidden space-x-3 group px-4 -mx-4">
      <SelectedProductsListItemPreview
        selectedProduct={selectedProduct}
        onLightboxOpen={onLightboxOpen}
      />

      <div className="flex-1 overflow-hidden text-sm">
        <div className="font-medium truncate">
          <TextWithTooltip
            text={product?.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
        <div className="dark:text-gray-500 text-gray-600 truncate">
          <ProductsListItemNda product={product} />
          <TextWithTooltip
            text={product?.brand?.name}
            tooltipPlacement={TooltipPlacement.BOTTOM}
          />
        </div>
      </div>

      <div className="flex-shrink-0 relative flex items-center justify-end">
        <TooltipSingletonSourceWrapper
          placement={TooltipPlacement.BOTTOM}
          withArrow
        >
          <CheckPermissions
            action={
              ProductsPermissions.READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
            }
          >
            <SubmitProductToProjectOrTaskModalButton
              product={product}
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              SelectedProductsPermissions.READ_SELECTED_PRODUCTS_DOWNLOAD_BUTTON
            }
          >
            <DownloadSelectedProductModalButton
              selectedProductUuid={selectedProduct.uuid}
              className="dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
              icon={IconsEnum.DOWNLOAD_OUTLINE}
              iconClassName="h-5 w-5"
              tooltipI18nText={words.download}
              tooltipSingleton
            />
          </CheckPermissions>
        </TooltipSingletonSourceWrapper>

        <SelectedProductsListItemDeleteButton
          selectedProduct={selectedProduct}
          productsSet={productsSet}
          productsSetCacheKeys={productsSetCacheKeys}
          className="-mr-2 dark:focus:ring-gray-100 dark:hover:bg-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1.5 rounded-md text-gray-400 hover:text-gray-700 absolute focus:relative focus:opacity-100 opacity-0 group-hover:opacity-100 group-hover:relative"
          icon={IconsEnum.CROSS}
          iconClassName="h-5 w-5"
          onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
          updateProductsSetCache={updateProductsSetCache}
        />
      </div>
    </li>
  );
}

export default memo<SelectedProductsListItemProps>(SelectedProductsListItem);
