import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { SelectedProductUUID } from '../../../selectedProductsTypes';

import {
  DownloadSelectedProductQueryResponse,
  DOWNLOAD_SELECTED_PRODUCT_QUERY
} from '../../../queries/downloadSelectedProduct.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { useDownloadSelectedProduct } from '../../../hooks/useDownloadSelectedProduct';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

interface DownloadSelectedProductButtonProps {
  selectedProductUuid: SelectedProductUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadSelectedProductButton({
  selectedProductUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadSelectedProductButtonProps) {
  const {
    downloadSelectedProduct,
    downloadSelectedProductLoading,
    downloadSelectedProductErrorMessage
  } = useDownloadSelectedProduct<DownloadSelectedProductQueryResponse>({
    query: DOWNLOAD_SELECTED_PRODUCT_QUERY
  });

  useShowToastOnErrorChange({ error: downloadSelectedProductErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadSelectedProduct({
      uuid: selectedProductUuid,
      deviceNanoId: downloadNanoId
    });
  }, [selectedProductUuid, downloadNanoId, downloadSelectedProduct]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      disabled={downloadSelectedProductLoading}
      onClick={handleClick}
    />
  );
}

export default DownloadSelectedProductButton;
