import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, I18nText } from '../../../../../types';
import { SelectedProductUUID } from '../../../selectedProductsTypes';

import { useDownloadSelectedProduct } from '../../../hooks/useDownloadSelectedProduct';
import {
  DOWNLOAD_SELECTED_PRODUCT_QUERY,
  DownloadSelectedProductQueryResponse
} from '../../../queries/downloadSelectedProduct.query';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadManagerModal } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/hooks/useDownloadManagerModal';

import { DownloadManagerModalItems } from '../../../../common/components/footers/DefaultFooter/components/UserDownloadManagerModalButton/components/DownloadManagerModalItems';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { DownloadCache } from '../../../../downloads/DownloadCache';
import { downloadsKeys, words } from '../../../../../locales/keys';

interface DownloadSelectedProductModalButtonProps {
  selectedProductUuid: SelectedProductUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadSelectedProductModalButton({
  selectedProductUuid,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadSelectedProductModalButtonProps) {
  const {
    downloadManagerBlockOpened,
    downloads,
    downloadsFetched,
    downloadsError,
    handleStopAll,
    handleClear,
    deleteDownloadsErrorMessage,
    deleteDownloadsLoading
  } = useDownloadManagerModal();

  const {
    downloadSelectedProduct,
    downloadSelectedProductLoading,
    downloadSelectedProductErrorMessage
  } = useDownloadSelectedProduct<DownloadSelectedProductQueryResponse>({
    query: DOWNLOAD_SELECTED_PRODUCT_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({
    error: downloadSelectedProductErrorMessage || deleteDownloadsErrorMessage
  });

  const { downloadNanoId } = useDownloadNanoId();

  const handleDownload = useCallback(() => {
    downloadSelectedProduct({
      uuid: selectedProductUuid,
      deviceNanoId: downloadNanoId
    });
  }, [selectedProductUuid, downloadNanoId, downloadSelectedProduct]);

  return (
    <SimpleModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      icon={icon}
      i18nTitle={downloadsKeys.plural}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipSingleton={tooltipSingleton}
      disabled={downloadSelectedProductLoading}
      onOpen={handleDownload}
      isModalOpen={downloadManagerBlockOpened}
      submitClass="font-medium rounded-md px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      submitButtonColor="gray"
      i18nSubmitText={words.stopAll}
      onSubmit={handleStopAll}
      onCancel={handleClear}
      i18nCancelText={words.clear}
      closeOnCancel={false}
      cancelButtonPosition="start"
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      withoutButtons={isEmpty(downloads)}
      isLoading={deleteDownloadsLoading}
    >
      <DownloadManagerModalItems
        downloads={downloads}
        downloadsFetched={downloadsFetched}
        downloadsError={downloadsError}
      />
    </SimpleModalButton>
  );
}

export default DownloadSelectedProductModalButton;
