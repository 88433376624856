import React from 'react';

import { ProductID, ProductUUID } from '../../../../../productsTypes';
import { ClassName } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useSubmitProductToProjectOrTask } from '../../../../../hooks/useSubmitProductToProjectOrTask';

import { SelectProjectAndTaskModalButton } from '../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { productsKeys, words } from '../../../../../../../locales/keys';

interface SubmitProductToProjectOrTaskModalButtonProduct {
  uuid: ProductUUID;
  id: ProductID;
}

interface SubmitProductToProjectOrTaskModalButtonProps {
  product: SubmitProductToProjectOrTaskModalButtonProduct;
  className?: ClassName;
  tooltipPlacement?: TooltipPlacement;
}

function SubmitProductToProjectOrTaskModalButton({
  product,
  className,
  tooltipPlacement = TooltipPlacement.LEFT
}: SubmitProductToProjectOrTaskModalButtonProps) {
  const {
    submitProductToProjectOrTask,
    submitProductToProjectOrTaskError,
    submitProductToProjectOrTaskIsLoading,
    submitProductToProjectOrTaskReset
  } = useSubmitProductToProjectOrTask({ product });

  return (
    <SelectProjectAndTaskModalButton
      className={
        className ||
        'bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow'
      }
      icon={IconsEnum.PAPER_CLIP_SOLID}
      iconClassName="h-5 w-5 stroke-1.75"
      i18nSubmitText={words.attach}
      i18nTitle={productsKeys.attachSelected}
      tooltipSingleton
      tooltipI18nText={words.attach}
      tooltipPlacement={tooltipPlacement}
      isLoading={submitProductToProjectOrTaskIsLoading}
      errorMessage={submitProductToProjectOrTaskError}
      onClose={submitProductToProjectOrTaskReset}
      onSubmit={submitProductToProjectOrTask}
    />
  );
}

export default SubmitProductToProjectOrTaskModalButton;
