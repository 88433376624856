import {
  AssociationIDsFilter,
  BooleanFilter,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsClearItemsFilters,
  FetchItemsShowItem,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  IdFilterType,
  TextFilterType,
  FetchItemsFilterItems,
  DeleteItemGqlStatus,
  DeleteItemGqlQuery,
  ItemGqlError,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  StringFilter
} from '../../../types';

import {
  FetchCategoriesCacheKey,
  FetchCategoriesFilters,
  FetchCategoriesLimit,
  FetchCategoriesPage,
  FetchCategoriesSort
} from '../../categories/categoriesTypes';

export interface FetchProductsFilters {
  name?: TextFilterType;
  brandId?: IdFilterType;
  productCategoryId?: IdFilterType;
  productParentCategoryIds?: AssociationIDsFilter;
  styleId?: IdFilterType;
  materialIds?: AssociationIDsFilter;
  colorIds?: AssociationIDsFilter;
  favorite?: BooleanFilter;
  nda?: BooleanFilter;
  blocked?: BooleanFilter;
  clientIds?: AssociationIDsFilter;
  nameSku?: StringFilter;
  sku?: TextFilterType;
}

export type FetchProductsFiltersToPersist = (keyof FetchProductsFilters)[];

export enum FetchProductsSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchProductsFilterFields {
  NAME = 'name',
  NAME_SKU = 'nameSku',
  BRAND_ID = 'brandId',
  PRODUCT_CATEGORY_ID = 'productCategoryId',
  PRODUCT_PARENT_CATEGORY_IDS = 'productParentCategoryIds',
  STYLE_ID = 'styleId',
  MATERIAL_IDS = 'materialIds',
  COLOR_IDS = 'colorIds',
  BLOCKED = 'blocked',
  FAVORITE = 'favorite',
  NDA = 'nda',
  CREATED_AT = 'createdAt',
  CLIENT_IDS = 'clientIds'
}

export enum FetchProductsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  SELECTED_PRODUCTS_COUNT_DESC = 'SELECTED_PRODUCTS_COUNT_DESC',
  USERS_CHOSEN_PRODUCTS_COUNT_DESC = 'USERS_CHOSEN_PRODUCTS_COUNT_DESC'
}

export type FetchProductsGqlQuery = FetchItemsGqlQuery;
export type FetchProductGqlQuery = FetchItemGqlQuery;

export type FetchProductCacheKey = FetchItemCacheKey;
export type FetchProductsCacheKey = FetchItemsCacheKey;
export type FetchProductsCacheKeys = FetchProductsCacheKey[];
export type FetchProductCategoriesCacheKey = FetchCategoriesCacheKey;

export type FetchProductsFetched = FetchItemsFetched;
export type FetchProductsErrorMessage = FetchItemsErrorMessage;
export type FetchProductsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchProductsPage = FetchItemsPage;
export type FetchProductsLimit = FetchItemsLimit;
export type FetchProductsSort = FetchProductsSortTypes[];
export type FetchProductsTotalCount = FetchItemsTotalCount;
export type FetchProductsFilterProducts =
  FetchItemsFilterItems<FetchProductsFilters>;
export type FetchProductsSortProducts = FetchItemsSortItems;
export type FetchProductsPaginateProducts = FetchItemsPaginateItems;
export type FetchProductsPrefetchProducts = FetchItemsPrefetchItems;
export type FetchProductsClearProductsFilters = FetchItemsClearItemsFilters;
export type FetchProductsShowProduct = FetchItemsShowItem;
export type FetchProductsEditProduct = FetchItemsEditItem;

export type FetchProductCategoriesFilters = FetchCategoriesFilters;
export type FetchProductCategoriesLimit = FetchCategoriesLimit;
export type FetchProductCategoriesSort = FetchCategoriesSort;
export type FetchProductCategoriesPage = FetchCategoriesPage;

export type DeleteProductGqlQuery = DeleteItemGqlQuery;
export type DeleteProductGqlStatus = DeleteItemGqlStatus;
export type ProductGqlError = ItemGqlError;

export interface FetchProductsFiltersFilters {
  scope?: [FetchProductsFilterFields];
}
